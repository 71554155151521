import React, { useEffect, useState, } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';

import PotatoFollower from './router/PotatoFollower.js';

import Main from './router/Main.js';
import Header from './router/Header.js';
import FakeHeader from './router/FakeHeader.js';

import NotFound from './router/NotFound.js';

import Live from './router/Live.js';

import Schedule from './router/Schedule.js';

import SNS from './router/SNS.js';
import Youtube from './router/Youtube.js';
import X from './router/X.js';

const fetchSAringInfo = () => {
    return fetch(`${window.location.origin}/api/chzzk/get_follower_count`)
        .then(response => response.json())
        .then(data => data['body'])
        .catch(error => {
            return fetch(`http://127.0.0.1:8000/api/chzzk/get_follower_count`)
                .then(response => response.json())
                .then(data => data['body'])
                .catch(error => {
                    console.error('Error:', error);
                    throw error; // Optional: Rethrow the error if needed
                });
        });
};

const fetchAringChannelInfo = () => {
    return fetch(`${window.location.origin}/api/chzzk/get_stream_info`)
        .then(response => response.json())
        .then(data => data['body'])
        .catch(error => {
            return fetch(`http://127.0.0.1:8000/api/chzzk/get_stream_info`)
                .then(response => response.json())
                .then(data => data['body'])
                .catch(error => {
                    console.error('Error:', error);
                    throw error;
                });
        });
};

const fetchBroadcastRemainingTime = () => {
    return fetch(`${window.location.origin}/api/chzzk/get_stream_info`)
        .then(response => response.json())
        .then(data => data['body'])
        .catch(error => {
            return fetch(`http://127.0.0.1:8000/api/chzzk/get_stream_info`)
                .then(response => response.json())
                .then(data => data['body']['time'])
                .catch(error => {
                    console.error('Error:', error);
                    throw error;
                });
        });
};

const fetchYoutubeVideoInfo = () => {
    return fetch(`${window.location.origin}/api/youtube/get_exist_video`)
        .then(response => response.json())
        .then(data => data)
        .catch(error => {
            return fetch(`http://127.0.0.1:8000/api/youtube/get_exist_video`)
                .then(response => response.json())
                .then(data => data)
                .catch(error => {
                    console.error('Error:', error);
                    throw error;
                });
        });
};

const fetchProfilePic = () => {
    return fetch(`${window.location.origin}/api/chzzk/get_profile_pic`)
        .then(response => response.json())
        .then(data => data['body'])
        .catch(error => {
            return fetch(`http://127.0.0.1:8000/api/chzzk/get_profile_pic`)
                .then(response => response.json())
                .then(data => data['body'])
                .catch(error => {
                    console.error('Error:', error);
                    throw error;
                });
        });
};

const fetchScheduleJSON = () => {
    return fetch(`${window.location.origin}/api/schedule/load`)
        .then(response => response.json())
        .then(data => data['data'])
        .catch(error => {
            return fetch(`http://127.0.0.1:8000/api/schedule/load`)
                .then(response => response.json())
                .then(data => data['data'])
                .catch(error => {
                    console.error('Error:', error);
                    throw error;
                });
        });
};

const getTime = () => {
    const timeStamp = Date.now();
    const time = new Date(timeStamp);

    const timeString = time.getFullYear() + "년 "
                        + ( time.getMonth() + 1 ) + "월 "
                        + time.getDate() + "일 "
                        + time.getHours() + "시 "
                        + time.getMinutes() + "분 "
                        + time.getSeconds() + "초";

    return timeString;
}

const getLiveTime = (time) => {
    var currentDate = new Date();

    // 주어진 날짜 생성 (2024-02-21T14:04:28)
    var targetDate = new Date(time);

    // 두 날짜 간의 차이 계산 (밀리초 단위)
    var timeDifference = currentDate - targetDate;

    // 차이를 일, 시간, 분, 초로 변환
    var hours = Math.floor(timeDifference / (1000 * 60 * 60));
    var minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return hours + "시간 "
            + minutes + "분 "
            + seconds + "초";
}

const ForceRedirect = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(props.url);
    });

    return null;
};

function App() {

    const [ lastUpdate, setLastUpdate ] = useState(getTime());
    
    const [ isClickable, setIsClickable ] = useState(false);
    const [ userName, setUserName ] = useState('username');
    const [ border, setBorder ] = useState('');
    const [ liveClass, setLiveClass ] = useState('');
    const [ liveInfoClass, setLiveInfoClass ] = useState('');
    const [ liveProfileClass, setLiveProfileClass ] = useState('');
    const [ liveTitleClass, setLiveTitleClass ] = useState('');
    const [ liveTitle, setLiveTitle ] = useState('');
    const [ isLiveNow, setIsLiveNow ] = useState('fetching..');
    const [ videoDivImage, setVideoDivImage ] = useState('');
    const [ offLineImage, setOffLineImage ] = useState('');
    const [ follower, setFollower ] = useState('fetching..');
    const [ profilePicImage, setProfilePicImage ] = useState('');
    const [ scheduleProfilePicImage, setScheduleProfilePicImage ] = useState('');
    const [ dDay, setDday ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ isFetched, setisFetched ] = useState(false);

    const [ title, setTitle ] = useState('');
    const [ game, setGanme ] = useState('');
    const [ viewer, setViewer ] = useState('');
    const [ liveTime, setLiveTime ] = useState('');

    const [ discordLink ] = useState('https://discord.gg/Aring');
    const [ youtube ] = useState('링튜브');
    const [ youtubeLink ] = useState('https://youtube.com/@_A_ring');
    const [ xLink ] = useState('https://twitter.com/Arrrrring');
    const [ chzzkLink ] = useState('https://chzzk.naver.com/live/869474ef6644ddc45c81a65b03975dce');
    const [ cafeLink]  = useState('https://cafe.naver.com/aringqueen');

    const [ videoList, setVideoList ] = useState(['server_emptyy']);

    const [ scheduleDate, setScheduleDate ] = useState('2005년 8월 16일 화요일');
    const [ scheduleDateObject, setScheduleDateObject ] = useState();
    const [ scheduleLink, setScheduleLink ] = useState(null);

    const changeScheduleLink = (dataNum) => {
        fetchScheduleJSON()
            .then((data) => {
                setScheduleLink(data[dataNum]['link']);
            });
    };

    const changescheduleDateObject = (obj) => {
        setScheduleDateObject(obj)
    };

    useEffect(() => {
        fetchBroadcastRemainingTime()
            .then((data) => {
                setLiveTime(getLiveTime(data));
            })
        const now = new Date();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const firstDate = new Date('2023-07-06');

        let dateDiff = now - firstDate;
        let dayDIff = Math.ceil( dateDiff / ( 1000 * 60 * 60 * 24 ) ) + 1 ;
        setDday(dayDIff);

        fetchAringChannelInfo()
            .then((data) => {
                const currentTime = new Date().getTime();
                if (data['status'] === 'CLOSE') {
                    console.log('닫혀있음')
                    setLiveTime(getLiveTime(data['time']));
                    fetchProfilePic()
                        .then((data) => {
                            setProfilePicImage(data['channelImageUrl']);
                        })
                    setLiveTitle('');
                    setIsLiveNow('쉬는중');
                    setLiveClass('');
                    setLiveInfoClass('disabled');
                    setLiveProfileClass('disabled');
                    setLiveTitleClass('disabled');
                    setBorder('');
                    setIsClickable(false);
                    fetchSAringInfo()
                        .then((data) => {
                            setisFetched(true);
                            setUserName(data['content']['channelName']);
                            setVideoDivImage(data['offlineImage']);
                            setOffLineImage(data['offlineImage']);
                            setDescription(data['content']['channelDescription']);
                            setFollower(data['content']['followerCount']);
                        })
                } else {
                    console.log('열려있음')
                    const imageURL = data['liveImageUrl'].replace('{type}', '1920') + `?timestamp=${currentTime}`;
                    fetchProfilePic()
                        .then((data) => {
                            setProfilePicImage(data['channelImageUrl']);
                        })
                    setIsLiveNow('방송중!');
                    setIsClickable(true);
                    setLiveClass('liveVideo');
                    setLiveInfoClass('LiveInfo');
                    setLiveProfileClass('LiveProfile');
                    setLiveTitleClass('LiveTitle');
                    setLiveTitle('클릭하면 치지직 링크를 엽니다.');
                    setTitle(data['liveTitle']);
                    setGanme(data['liveCategory']);
                    setViewer(data['concurrentUserCount']);
                    setVideoDivImage(imageURL);
                    setBorder('0 0 0 2px rgba(0, 0, 0, 0.5), 0 0 0 5px #d87989');
                    fetchSAringInfo()
                        .then((data) => {
                            setOffLineImage(data['offlineImage']);
                            setisFetched(true);
                            setUserName(data['content']['channelName']);
                            setDescription(data['content']['channelDescription']);
                            setFollower(data['content']['followerCount']);
                        })
                }
            })
        fetchScheduleJSON()
            .then((data) => {
                setScheduleLink(data[0]['link']);
                setScheduleDateObject( { value: 0, label: data[0]['date'] } );
            });
        
        fetchProfilePic()
            .then((data) => {
                setScheduleProfilePicImage(data['channelImageUrl']);
            });
        const intervalId = setInterval(() => {
            fetchBroadcastRemainingTime()
                .then((data) => {
                    setLiveTime(getLiveTime(data));
                })
                
            const now = new Date();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();

            if ( minutes === 0 && seconds === 0 ) {
                setDday(dayDIff);
            }

            if ( seconds % 15 === 0 ) {
                fetchAringChannelInfo()
                    .then((data) => {
                        const currentTime = new Date().getTime();
                        const imageURL = data['liveImageUrl'].replace('{type}', '1920') + `?timestamp=${currentTime}`;
                        if (data.length !== 0) {
                            setVideoDivImage(imageURL);
                        }
                    })
            }

            if ( seconds % 5 === 0 ) {
                fetchYoutubeVideoInfo()
                .then((data) => {
                    setVideoList(data);
                })

                fetchAringChannelInfo()
                    .then((data) => {
                        setLiveTime(getLiveTime(data['time']));
                        setLastUpdate(getTime());
                        if ( data['status'] === 'CLOSE' ) {
                            fetchProfilePic()
                                .then((data) => {
                                    setProfilePicImage(data['channelImageUrl']);
                                })
                            setLiveTitle('');
                            setIsLiveNow('쉬는중');
                            setLiveClass('');
                            setLiveInfoClass('disabled');
                            setLiveProfileClass('disabled');
                            setLiveTitleClass('disabled');
                            setBorder('');
                            setIsClickable(false);
                            fetchSAringInfo()
                                .then((data) => {
                                    setUserName(data['content']['channelName']);
                                    setVideoDivImage(data['offlineImage']);                                
                                    setOffLineImage(data['offlineImage']);
                                    setDescription(data['content']['channelDescription']);
                                    setFollower(data['content']['followerCount']);
                                })
                        } else {
                            fetchProfilePic()
                                .then((data) => {
                                    setProfilePicImage(data['channelImageUrl']);
                                })
                            fetchProfilePic()
                                .then((data) => {
                                    setProfilePicImage(data['channelImageUrl']);
                                })
                            setIsLiveNow('방송중!');
                            setIsClickable(true);
                            setLiveClass('liveVideo');
                            setLiveInfoClass('LiveInfo');
                            setLiveProfileClass('LiveProfile');
                            setLiveTitleClass('LiveTitle');
                            setLiveTitle('클릭하면 치지직 링크를 엽니다.');
                            setTitle(data['liveTitle']);
                            setGanme(data['liveCategory']);
                            setViewer(data['concurrentUserCount']);
                            setBorder('0 0 0 2px rgba(0, 0, 0, 0.5), 0 0 0 5px #d87989');
                            fetchSAringInfo()
                                .then((data) => {
                                    setOffLineImage(data['offlineImage']);
                                    setUserName(data['content']['channelName']);
                                    setDescription(data['content']['channelDescription']);
                                    setFollower(data['content']['followerCount']);
                                })
                        }
                    })
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="App nanumsquare">
            <Routes>
                <Route path='/Live/*' element={<ForceRedirect
                    url = '/Live' />}></Route>
                <Route path='/Live' element={[<Header />, <Live
                    chzzkLink = { chzzkLink } />]}></Route>
                <Route path='/' element={[<Header />, <Main
                    lastUpdate = { lastUpdate } 
                    isFetched = { isFetched }
                    isClickable = { isClickable }
                    userName = { userName }
                    title = { title }
                    game = { game }
                    viewer = { viewer }
                    liveTime = { liveTime }
                    border = { border }
                    liveClass = { liveClass }
                    liveTitle = { liveTitle }
                    liveInfoClass = { liveInfoClass }
                    liveProfileClass = { liveProfileClass }
                    liveTitleClass = { liveTitleClass }
                    isLiveNow = { isLiveNow }
                    follower = { follower }
                    videoDivImage = { videoDivImage }
                    profilePicImage = { profilePicImage }
                    description = { description }
                    dDay = { dDay }
                    discordLink = { discordLink }
                    youtube = { youtube }
                    youtubeLink = { youtubeLink }
                    xLink = { xLink }
                    chzzkLink = { chzzkLink }
                    cafeLink = { cafeLink }
                />]}
                />
                <Route path='/schedule/*' element={<ForceRedirect url = '/schedule' />}></Route>
                <Route path='/schedule' element={[<Header />, <Schedule scheduleDate = { scheduleDate }
                    scheduleLink = { scheduleLink }
                    scheduleProfilePicImage = { scheduleProfilePicImage }
                    scheduleDateObject = { scheduleDateObject }
                    fetchScheduleJSON = { fetchScheduleJSON }
                    changeScheduleLink = { changeScheduleLink }
                    changescheduleDateObject = { changescheduleDateObject }
                />]} />
                <Route path='/SNS' element={ [<Header />,<SNS />]}>
                    <Route path="youtube" element={<Youtube videoList = { videoList }
                    />} />
                    <Route path="x" element={<X />} />
                    <Route path='*' element={<ForceRedirect url = '/SNS/youtube' />}></Route>
                </Route>
                <Route path="/widgets/dearbb/follower" element={ <PotatoFollower/> }/>
                <Route path="*" element={[<FakeHeader />, < NotFound/>]}/>
            </Routes>
        </div>
    );
}

export default App;
