import React, { useEffect, useState } from 'react';
import Select from "react-select";
import './Schedule.css'

function Schedule(props) {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
        }),
        input: (provided) => ({
            ...provided,
            color: '#d87989'
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#141517',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'rgb(184, 184, 184);',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : (state.isFocused ? 'white' : '#d87989'),
            backgroundColor: state.isSelected ? '#d87989' : (state.isFocused ? '#d8798993' : '#141517'),
        }),
        multiValue: (provided) => ({
            ...provided,
            border: 'none',
            backgroundColor: 'purple',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'yellow',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'black',
            backgroundColor: 'blue',
            ':hover': {
                backgroundColor: 'darkblue',
                color: 'white',
            },
        }),
    };


    const profilePic = {
        backgroundImage: `url(${ props.scheduleProfilePicImage })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const [ temp, setTemp ] = useState([]);
    const [ date, setDate ] = useState();
    const [ selectedDate, setSelectedDate ] = useState();

    const handleDateChange = (selectedOption) => {
        setSelectedDate(selectedOption);
        setDate(selectedOption)
        props.changescheduleDateObject(selectedOption)
    };

    useEffect(() => {
        try {
            props.changeScheduleLink(date.value);
        } catch (e) {

        }
    }, [date])
    
    useEffect(() => {
        props.fetchScheduleJSON()
            .then((data) => {
                console.log(data);
                const tempArray = [];
                for (let i = 0; i < data.length; i++) {
                    tempArray.push({ value: i, label: `${data[i]['date']}` });
                }
                setTemp(tempArray);
                props.changescheduleDateObject(tempArray[0])
            });
    }, []);  // 빈 의존성 배열로 최초 렌더링 시에만 실행되도록 함
    
    if ( props.scheduleLink === null ) {
        return (
            <div className="scheduleDiv fadeIn">
                <div className="date">
                    { props.scheduleDate }
                </div>
                <div className="chatbox">
                    <div className="chatProfile">
                        <div className="profile_" style={profilePic}></div>
                    </div>
                    <div className="chat">
                        <div>
                            스케줄표 이미지를 가져오는 동안 잠시 기다려줘!
                        </div>
                        <div>
                            이미지가 계속 보이지 않는다면 개발자에게 연락해줘
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="scheduleDiv fadeIn">
                <div className="date">
                    <Select
                        styles={customStyles}
                        options={temp}
                        onChange={handleDateChange}
                        defaultValue={props.scheduleDateObject || temp[0]}
                    />
                </div>
                <div className="chatbox">
                    <div className="chatProfile">
                        <div className="profile_" style={profilePic}></div>
                    </div>
                    <div className="chat">
                        <div>
                            이번 주 스케줄표야!
                        </div>
                        <div>
                            <img onClick={ () => { window.open( process.env.PUBLIC_URL + `static/schedule/${ props.scheduleLink }.png`, '_blank') } } src={process.env.PUBLIC_URL + `static/schedule/${ props.scheduleLink }.png`} alt="Schedule"></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Schedule;