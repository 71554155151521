import React from 'react';
import { NavLink } from 'react-router-dom';

function FakeHeader() {
    return (
        <header>
            <div className="text">
                <span className="wrap">
                    <a href="/" >
                        <img
                            src={process.env.PUBLIC_URL + '/static/aringchive.ico'}
                            alt="aringchive" />
                    </a>
                    <span className="textSpan">
                        <span className="name">Aring</span>chive
                    </span>
                </span>
            </div>
            <div className="menu">
                <NavLink exact to="/Live">Live</NavLink>
                <NavLink exact to="/">홈</NavLink>
                <NavLink exact to="/schedule">방송일정</NavLink>
                <NavLink exact to="/SNS/youtube">SNS</NavLink>
                <NavLink activeClassName="active">???</NavLink>
            </div>
        </header>
    );
}

export default FakeHeader