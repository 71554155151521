import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import "./Follower.css";

function PotatoFollower() {
    const [ prev, setPrev ] = useState('');
    // const [dataList, setDataList] = useState([]);
    const temp = [];

    const playSound = () => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audio = new Audio(`${process.env.PUBLIC_URL}/static/boxing_bell.mp3`);
    
        const source = audioContext.createMediaElementSource(audio);
        source.connect(audioContext.destination);
    
        audio.play();
    };     

    const fetchData = () => {
        return fetch(`${window.location.origin}/api/chzzk/widget/get_follow_feed/dearbb`)
            .then(response => response.json())
            .then(data => {
                setPrev(prev => {
                    console.log(data['result'], prev);
                    return data['result'];
                });
            })
            .catch(error => {
                return fetch(`http://127.0.0.1:8000/api/chzzk/widget/get_follow_feed/dearbb`)
                    .then(response => response.json())
                    .then(data => {
                        setPrev(prev => {
                            console.log(data['result'], prev);
                            return data['result'];
                        });
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        throw error;
                    });
            });
    };    

    const constFetchData = async () => {
        $('.widget').removeClass('show');
        try {
            const response = await fetch(`${window.location.origin}/api/chzzk/widget/get_follow_feed/dearbb`);
            const data = await response.json();
            const now = data['result'];

            setPrev(prev => {
                if ( now !== prev && !(temp.includes(now)) ) {
                    temp.push(now);
                    $('.nickname').text(now);
                    $('.widget').addClass('show');
                    playSound();
                }
                return now; // 새로운 값으로 prev를 업데이트
            });
        } catch (error) {
            console.error('Error:', error);
            try {
                const fallbackResponse = await fetch(`http://127.0.0.1:8000/api/chzzk/widget/get_follow_feed/dearbb`);
                const fallbackData = await fallbackResponse.json();
                const now = fallbackData['result'];

                setPrev(prev => {
                    if ( now !== prev && !(temp.includes(now)) ) {
                        temp.push(now);
                        $('.nickname2').text(now);
                        $('.widget').addClass('show');
                        playSound();
                    }
                    return now; // 새로운 값으로 prev를 업데이트
                });
            } catch (fallbackError) {
                console.error('Fallback Error:', fallbackError);
                throw fallbackError;
            }
        }
    };

    useEffect(() => {
        fetchData(); // 컴포넌트 마운트 시에 데이터 초기화
        const intervalId = setInterval(constFetchData, 10000);

        // 언마운트 시에 인터벌 해제
        return () => clearInterval(intervalId);
    }, []); // 두 번째 매개변수로 빈 배열을 전달하여 한 번만 실행되도록 설정

    return (
        <div>
            <div className="widget">
                <span className="nickname2">닉네임</span>님이 팔로우했습니다!
            </div>
        </div>
    );
}

export default PotatoFollower;