import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Hls from 'hls.js';
import "./Live.css";

const LiveComp = (props) => {
    return (
        <div className="fadeIn Live">
            <div className="liveVideoDiv">
                <div className="block">
                    <p>
                        클릭하여 음소거 해제
                    </p>
                </div>
                <video className="video" controls autoplay muted>
                    ※ 영상을 재생할 수 없는 환경이에요.
                </video>
            </div>
            <p className="warn">
                ※ HLS 스트리밍 중입니다. 약 3~5초 정도의 딜레이가 존재합니다.
            </p>
            <p className="info">
                딜레이 없는 방송을 보고싶다면? <a href={props.chzzkLink} target="_blank" rel="noreferrer">치지직으로</a>
            </p>
        </div>
    )
}

const OffLineComp = () => {
    return (
        <div className="fadeIn Live">
            <div className="offLineDiv">
                <div className="offLineMessage">
                    채널이 오프라인 상태입니다.
                    <br/>
                    다음 라이브를 기대해 주세요!
                </div>
            </div>
            <p className="info schedule">
                다음 방송 일정이 궁금하다면?&nbsp;
                <NavLink exact to="/schedule">방송일정 보러가기</NavLink>
            </p>
        </div>
    )
}

function Live(props) {
    const [ isLiveNow, setIsLiveNow ] = useState(false);

    useEffect(() => {
        let video = document.querySelector('.video');
        let block = document.querySelector('.block');

        if ( video !== null ) {
            // 비디오 자동 재생 (음소거)
            video.autoplay = true;
            video.muted = true;

            // 재생 프로그래스 바 숨기기
            video.controls = false;

            // 클릭 시 음소거 해제
            block.addEventListener('click', function () {
                video.muted = false;
                block.style.display = 'none';
            });
        }

        fetch(`${window.location.origin}/api/chzzk/get_stream_info`)
            .then(response => response.json())
            .then(data => {
                if ( data['body']['status'] === 'CLOSE' ) {
                    setIsLiveNow(false);
                } else {
                    setIsLiveNow(true);
                }

                const url = data['body']['HLS'];
                if (url !== null) {
                    if (Hls.isSupported()) {
                        const hls = new Hls();
                        hls.loadSource(url);
                        hls.attachMedia(video);
                    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                        video.src = url;
                    }
                }
            })
            .catch(error => {
                return fetch(`http://127.0.0.1:8000/api/chzzk/get_stream_info`)
                    .then(response => response.json())
                    .then(data => {
                        if ( data['body']['status'] === 'CLOSE' ) {
                            setIsLiveNow(false);
                        } else {
                            setIsLiveNow(true);
                        }

                        const url = data['body']['HLS'];
                        if (url !== null) {
                            if (Hls.isSupported()) {
                                const hls = new Hls();
                                hls.loadSource(url);
                                hls.attachMedia(video);
                            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                                video.src = url;
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        throw error;
                    });
            });

        const intervalId = setInterval(() => {

            const now = new Date();
            const seconds = now.getSeconds();

            if ( seconds % 5 === 0 ) {
                fetch(`${window.location.origin}/api/chzzk/get_stream_info`)
                .then(response => response.json())
                .then(data => {
                    if ( data['body']['status'] === 'CLOSE' ) {
                        if ( isLiveNow ) {
                            setIsLiveNow(false);
                        }
                    } else {
                        if ( !(isLiveNow) ) {
                            setIsLiveNow(true);
                        }
                    }
                })
                .catch(error => {
                    return fetch(`http://127.0.0.1:8000/api/chzzk/get_stream_info`)
                        .then(response => response.json())
                        .then(data => {
                            if ( data['body']['status'] === 'CLOSE' ) {
                                if ( isLiveNow ) {
                                    setIsLiveNow(false);
                                }
                            } else {
                                if ( !(isLiveNow) ) {
                                    setIsLiveNow(true);
                                }
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                            throw error;
                        });
                });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [ isLiveNow ]);

    return (
        <>
            { isLiveNow
            ? <LiveComp chzzkLink = { props.chzzkLink }/>
            : <OffLineComp/> }
        </>
    );
}

export default Live;
