function X() {
    const temp = {
        color: 'white'
    }

    return (
        <div className="fadeIn" style={temp}>
            <h1>준비중입니다.</h1>
        </div>
    )
}

export default X;