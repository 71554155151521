import React, { useEffect } from 'react';
import $ from 'jquery';
import './youtube.css';

function Youtube(props) {
    useEffect(() => {
        const intervalId = setInterval(() => {
            const lists = props.videoList;
            if ( lists[0] === 'server_emptyy' ) {
                $('.alert_sub').text('아직 서버로부터 데이터를 전달받지 못했어요.')
            } else {
                if ( lists.length > 0 ) {
                    $('.alertContainer').remove();
                    $('.videoContainer').empty(); // 이전 내용을 제거하는 방법 변경
    
                    for (let i = 0; i < lists.length; i++) {
                        const videoId = lists[i]['id']; // 동적으로 바꿀 값
                        const temp = `
                            <div class="videoCard" onclick="window.open('https://youtu.be/${videoId}', '_blank')">
                                <img src="https://i.ytimg.com/vi/${videoId}/hqdefault.jpg" alt="thumbnail" />
                                <span class="videoTitle">${lists[i]['title']}</span>
                            </div>`;
                        $('.videoContainer').append(temp);
                    }
                } else {
                    $('.alert_sub').text('지금은 표시할 영상이 없어요.')
                }
            }
        }, 1000); // 1초 간격으로 체크하도록 수정

        // useEffect 함수가 반환하는 함수는 cleanup 함수로서 컴포넌트가 언마운트되거나 의존성이 변경될 때 호출됩니다.
        return () => clearInterval(intervalId);
    }, [props.videoList]);

    return (
        <div className="fadeIn">
            <div className="title">
                <span className="ring">Ring</span>
                <span>Tube</span>
            </div>
            <div className="videos">
                <span className="title_main">업로드된 모든 영상 보기</span>
                <span className="title_sub">API의 할당량 이슈를 방지하기 위해 정각마다 업데이트됩니다.</span>
                <div className="alertContainer">
                    <span className="alert_title">흠.. 조용하네요.</span>
                    <span className="alert_sub">이곳이 오래 비어있다면,
                    서버 가동 시간이 1시간 미만이거나, API의 문제로
                    내용이 표시되지 않은 경우에요.</span>
                </div>
                <div className="videoContainer">
                </div>
            </div>
        </div>
    );
}

export default Youtube;
